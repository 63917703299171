import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./RegistrationForm.css";
import diorLogoImg from "./images/COVER_Sauvage Eau Forte.jpg"; // Adjust the path as necessary
import diorBrushImg from "./images/dior_brush.jpg"; // Adjust the path as necessary
import { useLocation } from "react-router-dom";
import imgPUSH02 from "./images/PUSH02.jpg"; // Adjust the path as necessary
import imgPUSH03 from "./images/PUSH03.jpg"; // Adjust the path as necessary

const ConfirmPage = () => {
  const location = useLocation();
  const { value } = location.state || {};
  const [store, setStore] = React.useState("");

  useEffect(() => {
    if (value) {
      setStore(value);
    }
  }, []);

  return (
    <div> <div className="text-center">
    <img
      src={diorLogoImg}
      alt="Event"
      className="img-fluid mb-4"
      style={{ width: "100%", height: "auto", maxWidth: "max-content" }}
    />
  </div>
      <div className="container">
       
        <p style={{ textAlign: "center" }}>
          ขอบคุณสำหรับการลงทะเบียนเพื่อรับบริการจากดิออร์ 
          <br />
          Thank you for registration to receive service
          {/* <br />
        Thank you for participating in Plan de Paris Event at Hall of Fame, Siam
        Paragon */}
        </p>
        <br />
        <p style={{ textAlign: "center", fontWeight: "bold" }}>สาขา {store}</p>
        <br />
        <p style={{ textAlign: "center" }}>
          บริการแนะนำการเลือกน้ำหอมสไตล์ที่คุณชื่นชอบ
        </p>
        <p style={{ textAlign: "center" }}>
          พิเศษ
          <br />
          เลือกรับน้ำหอม Sauvage Eau Forte หรือ น้ำหอม J’adore ใดๆ ขนาด 1 ml
          และผลิตภัณฑ์บำรุงผิวขนาดทดลอง*
        </p>
        <p style={{ textAlign: "center" }}>
          ระหว่างวันที่ 15 ก.ย. - 31 ต.ค. 67
        </p>
        <p style={{ textAlign: "center" }}>
          รอรับข้อความ SMS
          และแสดงข้อความที่ได้รับที่จุดลงทะเบียนเพื่อรับสิทธิพิเศษภายในงาน{" "}
          <br />
          Wait for SMS message and show the received message at registration to
          get special privileges at the Event
        </p>
        <p style={{ textAlign: "center" }}>
          *รับผลิตภัณฑ์ขนาดทดลองหลังรับบริการ จำนวนจำกัด 1ท่าน : 1สิทธิ์
        </p>
      </div>

      {/* <div
        style={{ display: "flex", maxWidth: "100%", justifyContent: "center" }}
      >
        <div style={{ maxWidth: "325px" }}>
          <img
            src={imgPUSH03}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
        <div style={{ maxWidth: "325px" }}>
          <img
            src={imgPUSH02}
            alt="Event"
            // className="img-fluid mb-4"
            style={{ width: "100%", height: "auto", maxWidth: "none" }}
          />
        </div>
      </div> */}
    </div>
  );
};

export default ConfirmPage;
