import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { TbCloudDownload } from "react-icons/tb";
import { Button } from "reactstrap";

const ExportExcel = ({ data, fileName }) => {
  const exportToExcel = () => {
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and add the worksheet to it
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Create a Blob from the binary string and save it as a file
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff;
    const blob = new Blob([buf], { type: "application/octet-stream" });

    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <Button color="black" onClick={exportToExcel}>
      Export <TbCloudDownload style={{ marginRight: "8px" }} />
    </Button>
  );
};

export default ExportExcel;
