import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, NavLink, NavItem, Nav, Table,Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import eventImage from "./images/logo-dior_mobile_share.jpg"; // Adjust the path as necessary
import "./DashboardPage.css";
import LineChart from "./LineChart";
import TableDashboardDetail from "./TableDashboardCampign";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';

const DashboardPage = ({ handleLogout }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [campaigns, setCampaigns] = useState([]);
  const [tableCampaigns, setTableCampaigns] = useState([]);
  const [totalUser, setTotalUser] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  // useEffect(() => {
  //   axios
  //     .get(`${apiUrl}/dashboard`)
  //     .then((response) => {
  //       const data = response.data;
  //       const registrations = data.last7Days;

  //       // Extract labels (dates) and data (registration counts)
  //       const labels = registrations.map((item) => item.date);
  //       const registrationCounts = registrations.map((item) => item.count);

  //       // Prepare the data structure for the chart
  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: "Registrations per Day",
  //             data: registrationCounts,
  //             fill: false,
  //           //   borderColor: "rgb(75, 192, 192)",
  //             borderColor: "black",
  //             tension: 0,
  //           },
  //         ],
  //       });
  //       setTotalUser(data.activeRegistrations.count);
  //       setCampaigns(data.totalCampaigns.count);
  //       setTableCampaigns(data.campaignStatistics);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token'); // ดึง JWT token จาก localStorage
          
            const response = await axios.get(`${apiUrl}/dashboard`, {
                headers: {
                    'Authorization': `Bearer ${token}` // ส่ง JWT token ใน header
                },
                crossdomain: true,
            });

            const data = response.data;
            const registrations = data.last7Days;

            // Extract labels (dates) and data (registration counts)
            const labels = registrations.map((item) => item.date);
            const registrationCounts = registrations.map((item) => item.count);

            // Prepare the data structure for the chart
            setChartData({
                labels: labels,
                datasets: [
                    {
                        label: "Registrations per Day",
                        data: registrationCounts,
                        fill: false,
                        borderColor: "black",
                        tension: 0,
                    },
                ],
            });
            setTotalUser(data.activeRegistrations.count);
            setCampaigns(data.totalCampaigns.count);
            setTableCampaigns(data.campaignStatistics);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, []);

  const navigate = useNavigate();

  const onLogoutClick = () => {
      handleLogout();  // Call the handleLogout function passed as a prop
      navigate('/login');  // Redirect to the login page after logout
  };


  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs="12" md="2" style={{ padding: "0" , background:"black",minHeight: "95vh",height:"857px" }}>
            <div className="text-center">
              <img
                src={eventImage}
                alt="Event"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>
            <Nav vertical className="p-3">
              <NavItem>
                <NavLink href="/SauvageEauForte/dashboard" className="text-white">
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/SauvageEauForte/registerPage" className="text-white">
                Register
                </NavLink>
              </NavItem>
            <NavItem style={{ position: 'fixed',top: '420px'}}>
                {/* Add the Logout button here */}
                <NavLink href="/SauvageEauForte/registerPage" className="text-white" 
                onClick={onLogoutClick}>
                    Logout
                    </NavLink>
            </NavItem>
            </Nav>
          </Col>
          <Col xs="12" md="10" className="content">
            <Row>
              <Col xs="12" sm="6" lg="6" className="box">
              <div className="box-content-1">
                <LineChart data={chartData} />
                </div>
              </Col>
              <Col xs="12" sm="6" lg="3" className="box">
                <div className="box-content-2">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  Total Campaigns
                  <br></br>
                  <br></br>
                  <br></br>
                  <h1>{campaigns}</h1>
                </div>
              </Col>
              <Col xs="12" sm="6" lg="3" className="box">
                <div className="box-content-3">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  Total User Registered
                  <br></br>
                  <br></br>
                  <br></br>
                  <h1>{totalUser}</h1>
                </div>
              </Col>
            </Row>
            <Col xs="12" md="10" className="content">
          <h3 style={{margin:"0px"}}>Campaign List</h3>
          </Col>
            {/* <Row style={{margin:"0px 0px 16px 20px"}}>
                <h3>Campaign List</h3>
            </Row> */}
                {/* <h3>Campaign List</h3> */}

            <Row>
              <TableDashboardDetail campaigns={tableCampaigns} />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardPage;
