import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, NavLink, NavItem, Nav, Table,Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import eventImage from "./images/logo-dior_mobile_share.jpg"; // Adjust the path as necessary
import "./DashboardPage.css";
import LineChart from "./LineChart";
import TableDashboardCampignDeatail from "./TableDashboardCampignDeatail";
import ExportExcel from './ExportExcel';
import { useParams } from 'react-router-dom';
import { TbCloudDownload } from "react-icons/tb";


const DashboardDetailPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [campaignId, setCampaignId] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [totalUser, setTotalUser] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [tableCampaignDeatail, setTableCampaignDeatail] = useState([]);
  const { campaign_id, campaign_name } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [campaignName, setCampaignName] = useState(null);


  // useEffect(() => {
  //   axios
  //     .post(`${apiUrl}/dashboardBycampaignId`, { campaign_id: campaign_id })
  //     .then((response) => {
  //       const data = response.data;
  //       const registrations = data.last7Days;

  //       // Extract labels (dates) and data (registration counts)
  //       const labels = registrations.map((item) => item.date);
  //       const registrationCounts = registrations.map((item) => item.count);

  //       // Prepare the data structure for the chart
  //       setChartData({
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: "Registrations per Day",
  //             data: registrationCounts,
  //             fill: false,
  //           //   borderColor: "rgb(75, 192, 192)",
  //             borderColor: "black",
  //             tension: 0,
  //           },
  //         ],
  //       });
  //       setTotalUser(data.activeRegistrations.count);
  //       setTableCampaignDeatail(data.registrationDetails);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });

  //     const now = new Date();

  //     // Extract the year, month, and day
  //     const year = now.getFullYear();
  //     const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  //     const day = String(now.getDate()).padStart(2, '0');

  //     // Create the formatted date string
  //     const formattedDate = `${year}_${month}_${day}`;
  //     const nameExcel = "report_"+campaign_name+"_"+formattedDate;
  //     setCampaignName(nameExcel);
  // }, [campaign_id]);

    useEffect(() => {
      const fetchData = async () => {
          try {
              const token = localStorage.getItem('token'); // ดึง JWT token จาก localStorage

              const response = await axios.post(
                  `${apiUrl}/dashboardBycampaignId`, 
                  { campaign_id: campaign_id }, 
                  {
                      headers: {
                          'Authorization': `Bearer ${token}` // ส่ง JWT token ไปใน header
                      },
                      crossdomain: true,
                  }
              );

              const data = response.data;
              const registrations = data.last7Days;

              // Extract labels (dates) and data (registration counts)
              const labels = registrations.map((item) => item.date);
              const registrationCounts = registrations.map((item) => item.count);

              // Prepare the data structure for the chart
              setChartData({
                  labels: labels,
                  datasets: [
                      {
                          label: "Registrations per Day",
                          data: registrationCounts,
                          fill: false,
                          borderColor: "black",
                          tension: 0,
                      },
                  ],
              });
              setTotalUser(data.activeRegistrations.count);
              setTableCampaignDeatail(data.registrationDetails);

              // Format the current date for the Excel file name
              const now = new Date();
              const year = now.getFullYear();
              const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
              const day = String(now.getDate()).padStart(2, '0');
              const formattedDate = `${year}_${month}_${day}`;
              const nameExcel = "report_" + campaign_name + "_" + formattedDate;
              setCampaignName(nameExcel);
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };

      fetchData();
  }, [campaign_id]);

  return (
    <div>
      <Container fluid>
        <Row>
        <Col xs="12" md="2" style={{ padding: "0" , background:"black",minHeight: "95vh",height:"857px" }}>
        <div className="text-center">
              <img
                src={eventImage}
                alt="Event"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>
            <Nav vertical className="p-3">
              <NavItem>
                <NavLink href="/SauvageEauForte/dashboard" className="text-white">
                  Back
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12" md="10" className="content">
          <h4 style={{margin:"0px 0px 16px 20px"}}>Campaign</h4>
          <p style={{margin :"0px 0px 16px 20px"}} >{campaign_name}</p>
            <Row style={{marginTop:"-22px"}}>
              <Col xs="12" sm="6" lg="6" className="box">
              <div className="box-content-1">
                <LineChart data={chartData} />
                </div>
              </Col>
              <Col xs="12" sm="6" lg="6" className="box">
                <div className="box-content-3">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  Total User Registered
                  <br></br>
                  <br></br>
                  <h1>{totalUser}</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" lg="9" className="box" style={{margin:"0px 0px 16px 20px"}}>
                <p>Customer List</p>
              </Col>
              <Col xs="12" sm="12" lg="2" className="box" >
              <ExportExcel data={tableCampaignDeatail} fileName={campaignName} />
              </Col>
            </Row>

            <Row style={{margin:"-29px -10px -5px -9px"}}>
              <TableDashboardCampignDeatail campaigns={tableCampaignDeatail} />
            </Row>
          </Col>
        </Row>
      </Container> 
    </div>
    
  );
};

export default DashboardDetailPage;
