import React, { useState, useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import eventImage from "./images/PUSH_Sauvage Eau Forte.jpg"; // Adjust the path as necessary

const RegistrationForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
    eventSelect: "",
    chkSMSMMS: false,
    chkEmail: false,
    chkConsent: false,
    eventText: "",
    storeId: "",
    storeNameTh: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const inputRefs = {
    firstName: useRef(null),
    lastName: useRef(null),
    mobilePhone: useRef(null),
    email: useRef(null),
    eventSelect: useRef(null),
  };
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [dupModalOpen, setDupModalOpen] = useState(false);
  const [inputErrModalOpen, setInputErrModalOpen] = useState(false);
  const [concentsModal, setConcentsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storeOptions, setStoreOptions] = useState([]);


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update form data based on input type
    setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
    }));

    // Optional: Validate input fields as they change
    const errors = { ...formErrors };
    if (name !== "chkSMSMMS" && name !== "chkEmail" && name !== "chkConsent") {
        if (!value) {
            errors[name] = "กรุณากรอกข้อมูล";
        } else {
            delete errors[name];
        }
    }
    
    // Handle checkbox group validation
    if (name === "chkSMSMMS" || name === "chkEmail") {
      if(!formData.chkEmail && !formData.chkSMSMMS){
        delete errors.chkCommunication;
      }else if(name === "chkSMSMMS" && !formData.chkEmail){
            errors.chkCommunication = "กรุณาเลือกอย่างน้อยหนึ่งตัวเลือก";
      }else if(name === "chkEmail" && !formData.chkSMSMMS){
            errors.chkCommunication = "กรุณาเลือกอย่างน้อยหนึ่งตัวเลือก";
      } else {
          delete errors.chkCommunication;
      }
    }

    if (name === "chkConsent") {
        if (!checked) {
            errors.chkConsent = "กรุณายอมรับเงื่อนไข";
        } else {
            delete errors.chkConsent;
        }
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name === "email" && value && !emailPattern.test(value)) {
        errors.email = "อีเมลไม่ถูกต้อง";
      } else if (!value) {
        errors[name] = "กรุณากรอกข้อมูล";
      } else if(name === "email"){
        delete errors.email;
    }

    if (name === "mobilePhone") {
      const filteredValue = value.replace(/[^0-9]/g, '');
      if ((filteredValue.length < 10 || !filteredValue.startsWith('0')) && filteredValue !== '') {
          errors.mobilePhone = "หมายเลขโทรศัพท์มือถือจะต้องขึ้นต้นด้วย 0 และมีความยาวอย่างน้อย 10 หลัก";
      } else if (!filteredValue) {
          errors.mobilePhone = "กรุณากรอกข้อมูล";
      } else {
          delete errors.mobilePhone;
      }
      // อัพเดตข้อมูลฟอร์มด้วยค่าที่กรองแล้ว
      setFormData((prevData) => ({
          ...prevData,
          [name]: filteredValue,
      }));
    }
    // ถ้าเลือกข้อมูลจาก select
    if (name === "eventSelect") {
      const selectedStore = storeOptions.find(store => store.store_id === parseInt(value));
      setFormData((prevData) => ({
        ...prevData,
        storeId: selectedStore ? selectedStore.store_id : '',
        storeNameTh: selectedStore ? selectedStore.store_name_th : '',
      }));
    }
      setFormErrors(errors);
  };

  const handleConfirmClick = (e) => {
    e.preventDefault();
    
    const errors = {};

      Object.keys(formData).forEach((key) => {
        if (key !== "chkSMSMMS" && key !== "chkEmail" && key !== "chkConsent") {
          if (!formData[key]) {
            errors[key] = "กรุณากรอกข้อมูล";
          }
        }
      });

    if (!formData.chkSMSMMS && !formData.chkEmail) {
      errors.chkCommunication = "กรุณาเลือกอย่างน้อยหนึ่งตัวเลือก";
    }

    if (!formData.chkConsent) {
      errors.chkConsent = "กรุณายอมรับเงื่อนไข";
    }

    // Additional validations
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailPattern.test(formData.email)) {
      errors.email = "อีเมลไม่ถูกต้อง";
    }

    const mobilePattern = /^[0-9]+$/;
    if (formData.mobilePhone && !mobilePattern.test(formData.mobilePhone)) {
      errors.mobilePhone = "หมายเลขโทรศัพท์มือถือจะต้องเป็นตัวเลขเท่านั้น";
    } else if (formData.mobilePhone && (formData.mobilePhone.length < 10 || !formData.mobilePhone.startsWith('0'))) {
      errors.mobilePhone = "หมายเลขโทรศัพท์มือถือจะต้องขึ้นต้นด้วย 0 และมีความยาว 10 หลัก";
    }

    if (Object.keys(errors).length < 2 && (formErrors.eventText === 'กรุณากรอกข้อมูล' || formErrors.eventText === undefined)) {
      setConfirmationModalOpen(true);
    } else {
      setFormErrors(errors);
      setInputErrModalOpen(true);
    }

  };

  const handleConfirmationClose = () => setConfirmationModalOpen(false);

  const handleConfirmationConfirm = async () => {
    await handleSubmit();
  };

  const handleSubmit = async () => {
    setLoading(true);
    
    try {
      const response = await axios.post(`${apiUrl}/check-dup-and-insert`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
        crossdomain: true,
      });

      setConfirmationModalOpen(false);

      if (response.status === 201) {
        navigate('/SauvageEauForte/confirmation', {
          state: {
            value: formData.storeNameTh
          }
      });
      } else {
        setDupModalOpen(true);
      }
    } catch (error) {
      setConfirmationModalOpen(false);
      setErrorModalOpen(true); // Open the error modal

      console.error("API error:", error); // Optional: Log the error for debugging
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleErrorClose = () => {
    setErrorModalOpen(false); // Close the modal
  };

  const handleDupClose = () => {
    setFormErrors({});
    setDupModalOpen(false);
  };

  const handleOpenUrl = () => {
    setConcentsModal(true);
  };


  const handleInputErrClose = () => {
    setInputErrModalOpen(false);
  };
  const handelConcents = () => {
    setConcentsModal(false);
  };

  const [scrapedContent, setScrapedContent] = useState('<p>Loading...</p>');

  useEffect(() => {
    if (concentsModal) {
      fetch('https://shop.dior.co.th/pages/privacy-data-protection')
        .then(response => response.text())
        .then(data => setScrapedContent(data));
    }
  }, [concentsModal]);

  useEffect(() => {

    fetchStoreOptions();
    
    const updateModalStyle = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      let maxHeight = '80vh'; // Default max height
      let maxWidth = '105vw';

      // Adjust maxHeight based on viewport width
      if (width >= 1200) {
        maxHeight = '100vh';
      } else if (width >= 992) {
        maxHeight = '90vh';
      }
      // Adjust maxWidth based on viewport width
      if (width >= 1200) {
        maxWidth = '85vw';
      } else if (width >= 992) {
        maxWidth = '95vw';
      }

      // Adjust maxHeight for landscape orientation
      if (window.matchMedia('(orientation: landscape)').matches) {
        // maxHeight = '50vh';
        maxHeight = '60vh';
        maxWidth = '105vw'; // Adjust width for landscape mode
      }

      setModalStyle({
        maxHeight: maxHeight,
        overflowY: 'auto',
        overflowX: 'auto',
        padding: '0px',
      });
    };

    updateModalStyle(); // Initial call
    window.addEventListener('resize', updateModalStyle); // Update on resize

    return () => {
      window.removeEventListener('resize', updateModalStyle); // Clean up
    };
  }, []);

  const fetchStoreOptions = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/getStoresByStatus`, {}, {
        headers: {
          "Content-Type": "application/json",
        },
        crossdomain: true,
      });
      
      if (response.status === 200) {
        setStoreOptions(response.data.stores);
      } else {
        console.error('Failed to fetch stores:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching store options:', error);
    } finally {
      setLoading(false);
    }
  };

  const generateDateOptions = () => {
    return storeOptions.map((store) => (
      <option key={store.store_id} value={store.store_id}>
        {store.store_name_th}
      </option>
    ));
  };
  const [modalStyle, setModalStyle] = useState({});

  return (
    <div>
      <div className="text-center">
        <img
          src={eventImage}
          alt="Event"
          // className="img-fluid mb-4"
          style={{ width: "100%", height: "auto", maxWidth: "700px" }}
        />
      </div>
    <Container style={{ margin: "0 auto", padding: "24px 24px" }}>
      <Form>
        <FormGroup>
          <Label for="firstName">ชื่อ First name</Label>
          <Input
            id="firstName"
            name="firstName"
            type="text"
            value={formData.firstName}
            onChange={handleInputChange}
            invalid={!!formErrors.firstName}
            innerRef={inputRefs.firstName}
          />
          <FormFeedback>{formErrors.firstName}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="lastName">นามสกุล Last name</Label>
          <Input
            id="lastName"
            name="lastName"
            type="text"
            value={formData.lastName}
            onChange={handleInputChange}
            invalid={!!formErrors.lastName}
            innerRef={inputRefs.lastName}
          />
          <FormFeedback>{formErrors.lastName}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="mobilePhone">โทรศัพท์มือถือ Mobile phone</Label>
          <Input
            id="mobilePhone"
            name="mobilePhone"
            type="text" // Use 'text' to manually control validation and length
            value={formData.mobilePhone}
            onChange={handleInputChange}
            invalid={!!formErrors.mobilePhone}
            // maxLength="20" // Enforces the length constraint in the UI
            maxLength="10" // Enforces the length constraint in the UI
            innerRef={inputRefs.mobilePhone}
          />
          <FormFeedback>{formErrors.mobilePhone}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="email">อีเมล Email Address</Label>
          <Input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            invalid={!!formErrors.email}
            innerRef={inputRefs.email}
          />
          <FormFeedback>{formErrors.email}</FormFeedback>
        </FormGroup>
        <FormGroup>
      <Label for="eventSelect">เลือกสาขาในการเข้ารับบริการ Select counter to receive service</Label>
      <Input
        id="eventSelect"
        name="eventSelect"
        type="select"
        value={formData.eventSelect}
        onChange={handleInputChange}
        invalid={!!formErrors.eventSelect}
        innerRef={inputRefs.eventSelect}
        disabled={loading}
      >
        <option value="">กรุณาเลือก</option>
        {generateDateOptions()}
      </Input>
      <FormFeedback>{formErrors.eventSelect}</FormFeedback>
    </FormGroup>
        <hr style={{ margin: "25px auto" }} />
        <FormGroup>
          <Label style={{ marginBottom: "16px" }}>
            ประสงค์จะรับข่าวสารจาก ดิออร์ ผ่านช่องทาง ต่อไปนี้ <br />I wish to
            receive the following communication(s) from Dior
          </Label>
          <br />
          <Label check>
            <Input
              type="checkbox"
              id="chkSMSMMS"
              name="chkSMSMMS"
              checked={formData.chkSMSMMS}
              onChange={handleInputChange}
              style={{ 
                marginBottom: "18px",
                transform: "scale(1.15)", 
                border: "2px solid", 
                borderRadius: "3px",
                backgroundColor: formData.chkSMSMMS ? 'black' : 'white',
                boxShadow: 'none'
                }}
            />
            &nbsp;&nbsp;ส่งข้อความ SMS/MMS
          </Label>
          <br />
          <Label check>
            <Input
              type="checkbox"
              id="chkEmail"
              name="chkEmail"
              checked={formData.chkEmail}
              onChange={handleInputChange}
              style={{ 
                transform: "scale(1.15)", 
                border: "2px solid", 
                borderRadius: "3px",
                backgroundColor: formData.chkEmail ? 'black' : 'white',
                boxShadow: 'none'
                }}
            />
            &nbsp;&nbsp;อีเมล Email
          </Label>
          {formErrors.chkCommunication && (
            <FormFeedback className="d-block">
              {formErrors.chkCommunication}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label>
            <br />
            หมายเหตุ Notes <br />
            &nbsp;&nbsp;1)
            ข้อมูลทั้งหมดที่ท่านให้จะได้รับการเก็บรักษาไว้เป็นความลับ All
            Information provided will be kept in strict confidentiality.
            <br />
            &nbsp;&nbsp;2) คลิกด้านล่างเพื่อดูเงื่อนไขและรายละเอียดเพิ่มเติม
            Please tap the button below for more terms and conditions.
          </Label>
        </FormGroup>
        <FormGroup style={{ display: "flex", justifyContent: "center" }}>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
          borderRadius: "1px"}} 
          onClick={handleOpenUrl}>
            คลิกเพื่อดูข้อมูลส่วนตัวอื่นๆ <br />
            View Personal Information Collection Statement
          </Button>
        </FormGroup>
        <FormGroup>
          <Label>
            <Input
              type="checkbox"
              id="chkConsent"
              name="chkConsent"
              checked={formData.chkConsent}
              onChange={handleInputChange}
              style={{ 
                transform: "scale(1.15)", 
                border: "2px solid", 
                borderRadius: "3px",
                backgroundColor: formData.chkConsent ? 'black' : 'white',
                boxShadow: 'none'
                }}
            />
            &nbsp;&nbsp; ฉันได้อ่าน,
            ทำความเข้าใจและยอมรับเงื่อนไขรายละเอียดอื่นๆ เรียบร้อยแล้ว I have
            read, understood and agreed with the “PICS” terms and conditions
          </Label>
          {formErrors.chkConsent && (
            <FormFeedback className="d-block">
              {formErrors.chkConsent}
            </FormFeedback>
          )}
        </FormGroup>
      </Form>
      <div style={{ textAlign: "center" }}>
        <Button style={{ width: "100%",maxWidth: "200px",
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
          borderRadius: "1px" }} onClick={handleConfirmClick}>ยืนยัน Confirm</Button>

      </div>

      {/* Confirmation Modal */}
      <Modal isOpen={confirmationModalOpen} toggle={handleConfirmationClose}>
        <ModalHeader toggle={handleConfirmationClose}>
          ยืนยันการส่งข้อมูล
        </ModalHeader>
        <ModalBody>
          คุณต้องการยืนยันข้อมูลและส่งข้อมูลนี้หรือไม่?
          <br />
          Do you want to confirm and submit this information?
        </ModalBody>
        <ModalFooter>
        { loading ?
          <Button hidden>
            ยกเลิก
          </Button>
          :
          <Button style={{
            backgroundColor: "#000",
            borderColor: "#000",
            color: "#FFF",
          borderRadius: "1px"
        }} onClick={handleConfirmationClose}>
            ยกเลิก
          </Button>}
          { loading ? <Spinner color="secondary" /> :
          <Button style={{
            backgroundColor: "#000",
            borderColor: "#000",
            color: "#FFF",
          borderRadius: "1px"
        }} onClick={handleConfirmationConfirm}>
            ยืนยัน
          </Button> }
        </ModalFooter>
      </Modal>

      {/* Error Modal */}
      <Modal isOpen={errorModalOpen} toggle={handleErrorClose}>
        <ModalHeader toggle={handleErrorClose}>
          มีปัญหาในการเชื่อมต่อ
        </ModalHeader>
        <ModalBody>
          ระบบมีปัญหาในการส่งข้อมูล กรุณาลองอีกครั้งในภายหลัง
          <br />
          There was an issue submitting your data. Please try again later.
        </ModalBody>
        <ModalFooter>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }} onClick={handleErrorClose}>
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>

      {/* Dup Modal */}
      <Modal isOpen={dupModalOpen} toggle={handleDupClose}>
        <ModalHeader toggle={handleDupClose}>แจ้งเตือน</ModalHeader>
        <ModalBody>
          หมายเลขโทรศัพท์หรืออีเมลถูกใช้ลงทะเบียนไปแล้ว
          <br />
        </ModalBody>
        <ModalFooter>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }} onClick={handleDupClose}>
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>

      {/* Dup Modal */}
      <Modal isOpen={inputErrModalOpen} toggle={handleInputErrClose}>
        <ModalHeader toggle={handleInputErrClose}>แจ้งเตือน</ModalHeader>
        <ModalBody>
          กรุณากรอกข้อมูลให้ครบถ้วน
          <br />
        </ModalBody>
        <ModalFooter>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }} onClick={handleInputErrClose}>
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>

    <Modal size = "xl" isOpen={concentsModal} toggle={handelConcents}>
      <ModalHeader toggle={handelConcents}>Consent</ModalHeader>
        <div 
        style={modalStyle} dangerouslySetInnerHTML={{ __html: scrapedContent }}></div>
        <ModalFooter>
          <Button style={{
          backgroundColor: "#000",
          borderColor: "#000",
          color: "#FFF",
        borderRadius: "1px"
      }} onClick={handelConcents}>
            ตกลง
          </Button>
        </ModalFooter>
    </Modal>
    </Container>
    </div>
  );
};

export default RegistrationForm;
